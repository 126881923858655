import React, {useEffect} from 'react';
import { connect, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { clearCart, addDeliveryFee } from '../../redux/actions';
import api from '../../services/api';
import AsyncButton from '../util/async-button/AsyncButton';


export const CheckoutCartItem = props => {
  const history = useHistory();
  const {cartItem, currency} = props;
  return (
    <div className="CheckoutCartItem">
      <div className="imgWrapper">
        {(cartItem.signedImageurl && cartItem.signedImageurl !== 'not found' ) ? <img className="img" src={cartItem.signedImageurl} /> : <div className="defaultImg"> {cartItem.product.nombre[0]} </div>}
      </div>
      <img />
      <div className="productInfo">
        <span className="name">{`${cartItem.product.nombre}`}</span>
        <span className="name">{`${cartItem.presentacion}`}</span>
        <span className="quantity">{`Cantidad: ${cartItem.quantity}`}</span>
      </div>
      <span className="price" >{currency}{cartItem.price}</span>
    </div>
  );
};

CheckoutCartItem.defaultProps = {
  currency: '$',
};

const CheckoutCart = props => {
  const {
    cart, 
    history, 
    merchant, 
    checkout,
    name,
    email,
    phoneNumber,
    address, 
    clearCart,
    addDeliveryFeeM
  } = props;
  const orderType = checkout.orderType;

  useEffect(() => {
    let deliveryFee = 0.00;
    deliveryFee = orderType.id === 3 ? parseFloat(merchant.system_configuration.amountCostShipping) : 0.00;
    addDeliveryFeeM(deliveryFee);
  }, [orderType.id]);

  const handleOrderClick = async () => {
    const inputs = [name, email, phoneNumber, address];
    if(!inputs.every(p => p.length > 0 )) { return }

    await api.submitOrder().then( p => {
      clearCart();

      history.push(`/${merchant.user}/order/${p.id_pedido}`);
    });
  };

  return (
    <div className="CheckoutCart">
      <h2 className="cartTitle">Mi pedido</h2>
      <div className="cartItemList">
        {cart.items.map(p => (<CheckoutCartItem key={p.id_pres} cartItem={p} currency={merchant.moneda} />) )}
      </div>
      <div className="cartFooter">
        <div className="pricing">
          {
            orderType.id === 3 &&
            <p className="deliveryFee">
              Delivery {cart.deliveryFee > 0 ? <>{merchant.moneda}{cart.deliveryFee?.toFixed(2)}</> : <strong>Gratis</strong>}
            </p>
          }
          <p className="total">Total {merchant.moneda}{cart.totalAmount.toFixed(2)}</p>
        </div>
        <div className="btns">
          <button  className="backButton" onClick={()=> history.goBack()} > Volver</button>
          <AsyncButton className='orderButton' onClick={handleOrderClick} title='Ordenar'/>
        </div>
      </div>
    </div>
  );
}

const mapDispatchToProps = dispatch => ({
  clearCart: () => dispatch(clearCart()),
  addDeliveryFeeM: (fee)=> dispatch(addDeliveryFee(fee))
})

const mapStateToProps = state => ({
  checkout: state.orderFlow.activeFlow.checkout,
  cart: state.orderFlow.activeFlow.cart,
  merchant: state.merchant,
  name: state.orderFlow.activeFlow.checkout.name,
  email: state.orderFlow.activeFlow.checkout.email,
  phoneNumber: state.orderFlow.activeFlow.checkout.phoneNumber,
  address: state.orderFlow.activeFlow.checkout.address,
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CheckoutCart));
