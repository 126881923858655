import React from 'react';
import { connect } from 'react-redux';
import './assets/styles/app.scss';
import RestaurantPage from './components/RestaurantPage';
import {
  BrowserRouter as Router, 
  Switch,
  Route } from 'react-router-dom';
import Checkout from './components/Checkout';
import { createNewOrderFlow } from './redux/actions';
import PostOrder from './components/post-order/PostOrder';
import Landing from './pages/landing';

const App = props => {

  const {modal} = props;

  return (
    <div className="App" >
      <Router>
        {modal.show ? modal.element : <></>}
        <Switch >
          <Route exact path="/:merchantUser">
            <RestaurantPage />
          </Route>
          <Route exact path="/:merchantUser/checkout">
            <Checkout />
          </Route>
          <Route exact path="/:merchantUser/order/:orderId" >
            <PostOrder />
          </Route>
          <Route exact path="/">
            <Landing />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

const mapStateToProps = state => ({
  modal: state.modal,
});

const mapDispatchToProps = dispatch => ({
  createNewOrderFlow: (merchant) => dispatch(createNewOrderFlow(merchant)),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
