import { useEffect, useState } from 'react';
import styles from './Carrousel.module.scss';

const Carrousel = (props) => {
  const [currentIdx, setCurrentIdx] = useState(0);
  const {children, className, timing = 2000} = props;

  useEffect(() => {
    const t = setTimeout(() => {
      setCurrentIdx((old) => (old + 1) % children.length);
    }, timing);
    return () => clearInterval(t);
  }, [currentIdx]);

  return (
    <div className={[styles.Carrousel, className].join(' ')}>
      {
        children &&
        <div className={styles.show}>
          {children[currentIdx]}
        </div>
      }
    </div>
  );
}

export default Carrousel;
