import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import closeIcon from '../../assets/images/closeIcon.svg';

const ProductFilter = props => {
  const {closeFiltering, filterName, filteredName} = props;
  const [inputFilter, setInputFilter] = useState('');

  const handleInputChange = (e) => {
    const newValue = e.target.value;
    setInputFilter(newValue);
    filterName(newValue);
  }

  useEffect(() => {
    setInputFilter(filteredName);
  })

  return (
    <div className="productFilter">
      <input type="text" placeholder="Buscar productos" value={inputFilter} onChange={handleInputChange} />
      <img onClick={closeFiltering} className="closeIcon" src={closeIcon} alt="Close icon" />
    </div>
  );
}

export default ProductFilter;
