import React from 'react'
import { connect } from 'react-redux';
import { toggleModal } from '../../redux/actions';
import '../../assets/styles/util/header.scss';
import CartSidebar from '../restaurant_page/CartSidebar';
import CartIcon from '../restaurant_page/CartIcon';

const Header = props => {
  const {toggleModal, merchant} = props;

  const handleCartSidebarClose = () => {
    toggleModal(false);
  }

  const handleCartClick = () => {
    toggleModal(true, <CartSidebar handleClose={handleCartSidebarClose} />);
  }

  return (
    <header className="HomeHeader">
        <div>
          {(merchant.signedLogoUrl && merchant.signedLogoUrl !== 'not found') && <img className="logoImg" src={ merchant.signedLogoUrl} alt="Logo del comercio en Galda"/>}
        </div>
        <div className="merchantName">
          <h2>{merchant.commercialName}</h2>
        </div>
        <div>
          <CartIcon handleCartClick={handleCartClick} />
        </div>
    </header>
  );
}
const mapStateToProps = state => ({
  merchant: state.merchant,
});

const mapDispatchToProps = dispatch => ({
  toggleModal: (show, modal) => dispatch(toggleModal(show, modal)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
