import React, { useEffect, useState } from 'react';
import api from '../../services/api';

const CategoryItem = props => {
  const {categoryItem, pressed, filterCatg} = props;
  return (
    <div className={`categoryItem${pressed ? ' pressed' : ''}`}  onClick={() => filterCatg(categoryItem.id_catg)}>
      {categoryItem.descripcion}
    </div>
  );
};

const CategoryList = props => {
  const [categoryList, setCategoryList] = useState([]);

  const { filterCatg, filteredCatgId, merchantUrlName } = props;

  useEffect(() => {
    api.fetchCategories(merchantUrlName).then( categories => {
      const allPresentationsCategory = {
        id_catg: null,
        descripcion: 'Todos',
      }
      categories.unshift(allPresentationsCategory);
      setCategoryList(categories);
    }).catch(p => console.log(p));
  }, []);

  return (
    <div className="categoriesList">
      {categoryList
        .map(cat => 
        <CategoryItem key={cat.id_catg} categoryItem={cat} filterCatg={filterCatg} pressed={cat.id_catg === filteredCatgId} />
      )}
    </div>
  );
};

export default CategoryList;