import 
{ 
  ADD_INFO_TO_CHECKOUT,
  ADD_ITEM_CART,
  CHANGE_CHECKOUT_ORDER_TYPE,
  CLEAR_CART,
  CREATE_NEW_ORDER_FLOW,
  filterPresentationsCatg,
  filterPresentationsName,
  LOW_ITEM_QUANTITY_CART,
  REMOVE_ITEM_CART,
  ADD_DELIVERY_FEE  
} from "../actions";
import store from "../store";
import cartReducer from "./cartReducer";
import checkoutReducer from "./checkoutReducer";

const initialState = {
  activeFlow: {
    cart: {
      items: [],
      totalAmount: 0.00,
    },
    checkout: {
      orderType: {
        id: 1,
        name: 'Delivery',
        desc: `Por el momento sólo podrás realizar el pago al momento de recibir el 
              pedido o bien según los métodos aceptados por el local. Consulta a sus
              datos de contacto para más información.`,
      },
      name: '',
      phoneNumber: '',
      email: '',
      address: '',
    },
  },
  items: [],
};

const lookForMerchant = (orderFlows, merchantId) => {
  return orderFlows.find(p => p.id === merchantId );
};

const callCartReducer = (state, action) => {
  const oldState = state.items.slice();
  const activeFlow = {...state.activeFlow};
  const orderFlow = lookForMerchant(oldState, activeFlow.id);
  const newCartState = cartReducer(orderFlow.cart, action);
  Object.assign( orderFlow, {cart: newCartState});
  const newActiveFlow = {...orderFlow};

  return Object.assign({}, state, {activeFlow: newActiveFlow, items: oldState});
};

const callCheckoutReducer = (state, action) => {
  const orderFlowItems = state.items.slice();
  const activeFlow = {...state.activeFlow};

  const orderFlow = lookForMerchant(orderFlowItems, activeFlow.id);
  const newCheckoutState = checkoutReducer(orderFlow.checkout, action);
  Object.assign(orderFlow, {checkout: newCheckoutState});
  const newActiveFlow = {...orderFlow};

  return Object.assign({}, state, {activeFlow: newActiveFlow, items: orderFlowItems});
};

const orderFlowReducer = (state = initialState, action) => {
  switch(action.type) {
    case CREATE_NEW_ORDER_FLOW: {
      const oldItemsState = state.items.slice();
      const flowVisited = oldItemsState.find(p => p.id === action.merchant.id);

      if(!flowVisited) {
        const newOrderFlow = {
          ...action.merchant,
          cart: {
            items: [],
            totalAmount: 0.00,
          },
          checkout: {
            orderType: {
              id: 3,
              name: 'Delivery',
              desc: `Por el momento sólo podrás realizar el pago al momento de recibir el 
                    pedido o bien según los métodos aceptados por el local. Consulta a sus
                    datos de contacto para más información.`,
            },
            name: '',
            phoneNumber: '',
            email: '',
            address: '',
          },
        };

        const newState = Object.assign({}, state, {activeFlow: newOrderFlow, items: [...oldItemsState, newOrderFlow]});
        return newState;
      }
      const newState = Object.assign({}, state, {activeFlow: {...flowVisited} });
      return newState;
    }
    case ADD_ITEM_CART: {
      return callCartReducer(state, action);
    }
    case REMOVE_ITEM_CART: {
      return callCartReducer(state, action);
    }
    case CLEAR_CART: {
      return callCartReducer(state, action);
    }
    case ADD_DELIVERY_FEE: {
      return callCartReducer(state, action);
    }
    case LOW_ITEM_QUANTITY_CART: {
      return callCartReducer(state, action);
    }
    case CHANGE_CHECKOUT_ORDER_TYPE: {
      return callCheckoutReducer(state, action);
    }
    case ADD_INFO_TO_CHECKOUT: {
      return callCheckoutReducer(state, action);
    }
    default: return state;
  };
};

export default orderFlowReducer;
