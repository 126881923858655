import React from 'react';
import { connect } from 'react-redux';
import { addInfoToCheckout, changeCheckoutOrderType } from '../../redux/actions';

const Info = props => {
  const {
    selectedOrderType, 
    addInfoToCheckout,
    changeCheckoutOrderType,
    name,
    email,
    phoneNumber,
    address,
    merchant
  } = props;

  const orderTypes = [
    {
      id: 3,
      name: 'Delivery',
      desc: `Por el momento sólo podrás realizar el pago al momento de recibir el 
            pedido o bien según los métodos aceptados por el local. Consulta a sus
            datos de contacto para más información.`,
    },
    {
      id: 2,
      name: 'Para recoger',
      desc: `Por el momento sólo podrás realizar el pago al momento de recoger el 
            pedido.  Consulta a sus datos de contacto para más información.`,
    }
  ];

  return (
    <div className="Info">
      <div className="infoSection">
        <h2>Modalidad</h2>
        <div className="orderTypeSelector">
          {orderTypes
            .filter(p => {
              if(p.id === 3) 
              {
                return merchant?.system_configuration?.allowDeliveryMenu;
              }
              if(p.id === 2) 
              {
                return merchant?.system_configuration?.allowTakeAwayMenu;
              }
              return true;
            })
            .map( p => (
            <button key={p.id} className={selectedOrderType.id === p.id ? "selected" : ''} onClick={() => changeCheckoutOrderType(p)} >
              {p.name}
            </button>
          ))}
        </div>
      </div>
      <div className="infoSection">
        <h2>Medio de pago</h2>
        <p>
          {selectedOrderType.desc}
        </p>
      </div>
      <div className="infoSection">
        <h2>Mis datos</h2>
        <div className="inputWrapper">
          <label>Nombre</label>
          <input type="text" value={name} onChange={(e)=> addInfoToCheckout({name: e.target.value})} />
        </div>
        <div className="inputWrapper">
          <label>Correo electrónico</label>
          <input type="email" value={email} onChange={(e)=> addInfoToCheckout({email: e.target.value})}/>
        </div>
        <div className="inputWrapper">
          <label>Teléfono</label>
          <input type="text" value={phoneNumber} onChange={(e)=> addInfoToCheckout({phoneNumber: e.target.value})}/>
        </div>
        <div className="inputWrapper">
          <label>Dirección</label>
          <input type="text" value={address}  onChange={(e)=> addInfoToCheckout({address: e.target.value})}/>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = state => ({
  selectedOrderType: state.orderFlow.activeFlow.checkout.orderType,
  name: state.orderFlow.activeFlow.checkout.name,
  email: state.orderFlow.activeFlow.checkout.email,
  phoneNumber: state.orderFlow.activeFlow.checkout.phoneNumber,
  address: state.orderFlow.activeFlow.checkout.address,
});

const mapDispatchToProps = dispatch => ({
  changeCheckoutOrderType: (orderType) => dispatch(changeCheckoutOrderType(orderType)),
  addInfoToCheckout: (info) => dispatch(addInfoToCheckout(info)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Info);