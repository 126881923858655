import React, { useEffect, useState } from 'react'
import Backdrop from '../util/Backdrop';
import defImg from '../../assets/images/productImageDef.png'
import RightTopCloseButton from '../util/RightTopCloseButton';
import { connect, useSelector } from 'react-redux';
import { addItemCart } from '../../redux/actions';

const AddProductModal = props => {
  const {handleClose, productItem, addItemCart} = props;
  const merchant = useSelector(state => state.merchant);
  const [addedProduct, setAddedProduct] = useState({quantity: 1, totalAmount: productItem.precio, comment: ''});

  useEffect(() => {
    document.body.style.overflowY = 'hidden';
    return () => document.body.style.overflowY = 'auto';
  }, []);

  const operateQuantity = (dir) => {
    switch(dir) {
      case '-' : {
        setAddedProduct(old => {
          const newQuantity = old.quantity > 0 ? old.quantity-1 : old.quantity;
          const newTotalAmount = productItem.precio * newQuantity;
          return Object.assign({}, old, {quantity: newQuantity, totalAmount: newTotalAmount});
        });
        break;
      }
      case '+' : {
        setAddedProduct(old => {
          const newQuantity = old.quantity + 1;
          const newTotalAmount = productItem.precio * newQuantity;
          return Object.assign({}, old, {quantity: newQuantity, totalAmount: newTotalAmount});
        });
        break;
      }
    }
  }

  const handleCommentInput = (e) => {
    const commentValue = e.target.value;
    setAddedProduct((oldState) => Object.assign({}, oldState, {comment: commentValue} ) );
  }

  const handleAddItemClick = () => {
    addItemCart({...productItem, ...addedProduct});
    handleClose();
  }

  return (
    <Backdrop>
      <div className="AddProductModal">
        <RightTopCloseButton handleClose={handleClose}/>
        <div className="leftSection">
          <img src={(productItem.signedImageurl && productItem.signedImageurl !== 'not found' ) ? productItem.signedImageurl : defImg} alt="Product image" />
        </div>
        <div className="rightSection">
          <div className="productInfo">
            <h2 className="productName">{`${productItem.product.nombre} ${productItem.presentacion}`}</h2>
            {productItem?.product?.descripcion && <p className='productDescription'>{productItem?.product?.descripcion}</p>}
            <span className="sectionTitle">Información adicional</span>
            <textarea value={addedProduct.comment} onChange={handleCommentInput} className="commentInput" placeholder="Añade instrucciones especiales"/>
          </div>
          <div className="addToCartSection">
            <div className="quantitySelector">
              <button type="button" onClick={() => operateQuantity('-')}>-</button>
              <span>{addedProduct.quantity}</span>
              <button type="button" onClick={() => operateQuantity('+')}>+</button>
            </div>
            <button className="addToCartButton" type="button" onClick={handleAddItemClick}>
              <span>Agregar {merchant.moneda}{addedProduct.totalAmount}</span>
            </button>
          </div>
        </div>
      </div>
    </Backdrop>
  );
}

AddProductModal.defaultProps = {
  merchant: {
    moneda: '',
  }
};

const mapDispatchToProps = dispatch => ({
  addItemCart: (item) => dispatch(addItemCart(item)),
});

export default connect(null, mapDispatchToProps)(AddProductModal);
