import React from 'react';
import { useSelector } from 'react-redux';
import styles from './Footer.module.scss';
import { FaFacebook, FaInstagram, FaTwitter, FaWhatsapp } from "react-icons/fa";

const getWhatsAppLink = (wspNumber) => (wspNumber ? `https://wa.me/${wspNumber}` : '');

const Footer = () => {
  const merchant = useSelector(state => state.merchant);

  return (
    <footer className={styles.Footer}>
      <div className={styles.wrapper}>      
        <div className={styles.footerItem}>
          <div className={styles.storeInfo}>
            <div className={styles.address}>
              <h3>Dirección</h3>
              <span>{merchant.direccion}</span>
            </div>
            <div className={styles.phone}>
              <h3>Teléfono</h3>
              <span>{merchant.telefono}</span>
            </div>
            <nav className={styles.socialIcons}>
              {
                (merchant.facebook && merchant.system_configuration.enabledFacebook === 1) &&
                <li className={styles.socialItem}>
                  <a href={merchant.facebook} target="_blank" rel="noopener noreferrer">
                    <FaFacebook />
                  </a>
                </li>
                
              }
              {
                (merchant.instagram && merchant.system_configuration.enabledInstagram === 1) &&
                <li className={styles.socialItem}>
                  <a href={merchant.instagram} target="_blank" rel="noopener noreferrer">
                    <FaInstagram />
                  </a>
                </li>
              }
              {
                (merchant.whatsapp && merchant.system_configuration.enabledWhatsapp === 1) &&
                <li className={styles.socialItem}>
                  <a href={getWhatsAppLink(merchant.whatsapp)} target="_blank" rel="noopener noreferrer">
                    <FaWhatsapp />
                  </a>
                </li>
              }
              {
                (merchant.twitter && merchant.system_configuration.enabledTwitter === 1) &&
                <li className={styles.socialItem}>
                  <a href={merchant.twitter} target="_blank" rel="noopener noreferrer">
                    <FaTwitter />
                  </a>
                </li>
              }
            </nav>
          </div>
        </div>
        <div className={styles.footerItem}>
          <h3>Horarios</h3>
          <div className={styles.schedule}>
            <ul role="list" className={styles.days}>
              {
                merchant.menu_opening_hours?.filter(p => p.enabledMenu).map(p => (
                  <li key={p.idMenuOpeningHours}>
                    <span>{p.description}</span>
                    <span>{p.openingHour} a {p.endHour}</span>
                  </li>
                ))
              }
            </ul>
          </div>
        </div>
        <div className={styles.footerItem}>
          <div className={styles.powered}>
            <small>Powered by <a href="http://www.gal-da.com" target="_blank">Gal-Da</a></small>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer;
