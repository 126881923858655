import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { loadMerchantInfo } from '../../redux/actions';
import api from '../../services/api';
import ProductListItem from '../util/product-list-item/ProductListItem';
import styles from './PostOrder.module.scss';

const PostOrder = (props) => {
  const {merchant} = props;
  const {merchantUser, orderId} = useParams();
  const history = useHistory();
  const [orderDetails, setOrderDetails] = useState({
    total: 0.00,
    subTotal: 0.00,
    deliveryFee: 0.00,
  });

  useEffect(() => {    
    api.fetchMerchantInfo(merchantUser).then((merchantInfo) => {
      loadMerchantInfo(merchantInfo);
    });

    api.getPostOrderDetails(merchantUser, orderId)
    .then(response => {
      setOrderDetails(() => {
        const total = response.deliveryFee + response.subTotal;
        return Object.assign({}, response, {total});
      });
    });

  }, []);

  const handleBackToMerchantClick = () => {
    history.push(`/${merchantUser}`);
  }
  return (
    <main className={styles.PostOrder}>
      <div className={styles.contentWrapper} >
        {merchantUser && <img className={styles.merchantLogo} src={merchant.signedLogoUrl} alt="logo del comercio en gal-da" />}
        <h1 className={styles.thankYouText}>Gracias por tu compra</h1>
        <h2 className={styles.orderNumber}>
          Order #{orderDetails.nroPedido}
        </h2>
        
        <div className={styles.mainTextBox}>
          <p>Tu solicitud de orden han sido enviada</p>
          <p>
            En unos momentos te enviaremos un mensaje a tu correo
            electrónico <strong> {orderDetails.customerEmail} </strong>
          </p>
          {merchantUser && <button className={styles.backToMerchantBtn} onClick={handleBackToMerchantClick}>Volver al comercio</button>}
        </div>
        <section className={styles.orderInfo}>
          <div className={`${styles.infoWrapper} ${styles.productsSummary}`}>
            <span className={styles.title}>RESUMEN DE PRODUCTOS</span>
            <div className={styles.productsSummary}>
              {
                orderDetails?.presentations?.map(pres => (

                  <ProductListItem key={pres.id_pres} cartItem={pres} currency={merchant.moneda} />
                ))
              }
            </div>
          </div>
          <div className={`${styles.infoWrapper} ${styles.orderCost}`}>
            <span className={styles.title}>COSTOS</span>
            <div className={styles.costList} >
              <div className={styles.costItem}>
                <span>Subtotal productos</span>
                <span>{merchant.moneda}{orderDetails.subTotal.toFixed(2)}</span>
              </div>
              { 
                orderDetails.orderTypeId === 3 &&
                <div className={styles.costItem}>
                  <span>Costo de envío</span>
                  <span>{orderDetails.deliveryFee > 0 ? <>{merchant.moneda}{orderDetails.deliveryFee.toFixed(2)}</> : <strong>Gratis</strong> }</span>
                </div>
              }
            </div>
            <div className={styles.totalsInfo}>
              <span>Total costos</span>
              <span>{merchant?.moneda}{orderDetails?.total.toFixed(2)}</span>
            </div>
          </div>
        </section>
      </div>
    </main>
  );
}

const mapStateToProps = state => ({
  merchant: state.merchant,
});

const mapDispatchToProps = dispatch => ({
  loadMerchantInfo: (info) => dispatch(loadMerchantInfo(info)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PostOrder);
